.banner_background {
    width: 1440px;
    height: 524px;
    left: 1439.57px;
    top: 590.19px;

    background: linear-gradient(262.97deg, #CFDEF1 6.58%, rgba(207, 222, 241, 0) 44.28%);
    transform: rotate(-179.91deg);
}

.banner_image_right {
    width: 349px;
    height: 350px;
}